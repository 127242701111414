<template>
    <div>
        <div v-if="hasAccess()" class="row">
            <div v-if="$root.hasPermission('profiles_fieldset_add')" class="col-md-3 mb-2">
                <div class="card">
                    <h5 class="card-header">{{ $root.text('Profile field types', null, true) }}</h5>
                    <draggable
                        class="field-types list-group"
                        ghost-class="vue-drag-ghost"
                        drag-class="drag-item"
                        v-model="field_types"
                        :group="{ name: 'field-types', pull: 'clone', put: false }"
                        :sort="false"
                        animation="500"
                    >
                        <div class="list-group-item" v-for="item in field_types" :key="item.id">
                            {{ item.title }}
                            <div class="font-size-20 contact-links" style="position: absolute; right: 20px; top: 8px">
                                <i class="fal fa-arrows-alt"></i>
                            </div>
                        </div>
                    </draggable>
                </div>
            </div>

            <div class="col">
                <div class="card">
                    <h5 class="card-header">
                        {{ $root.text($root.contents.profile_title + ' fields', null, true) }}
                    </h5>
                    <div class="pt-0 pb-0">
                        <div v-if="errors" v-html="errors" class="alert alert-danger mt-3 ml-3 mr-3" role="alert"></div>

                        <div v-if="loading" class="mt-2"><loading></loading></div>

                        <div v-if="!loading" :class="containerClass()">
                            <div v-if="(profileFieldsCount() == 0)" class="drag-and-drop">
                                {{ $root.text('Drag & drop field types here', null, true) }}
                            </div>
                            <draggable
                                :class="profileFieldsClass()"
                                ghost-class="vue-drag-ghost"
                                v-model="profile_fields"
                                group="field-types"
                                handle=".drag-handle"
                                animation="200"
                                @add="onAddField"
                                @end="onMoveField"
                            >
                                <div v-for="item in profile_fields" :key="item.id">
                                    <div class="list-group-item">
                                        <div class="row">
                                            <div v-if="(item.id > 0)" class="col-sm-1 font-size-18 contact-links">
                                                <a
                                                    href="#"
                                                    class="drag-handle"
                                                    style="cursor: move"
                                                    v-b-tooltip.hover
                                                    :title="$root.text('Re-order', null, true)"
                                                    @click.prevent="doNothing()"
                                                >
                                                    <i class="fas fa-arrow-down-arrow-up"></i>
                                                </a>
                                                <span v-if="!item.edit_mode" class="d-sm-none float-right">
                                                    <a
                                                        v-if="$root.hasPermission('profiles_fieldset_edit')"
                                                        href="#"
                                                        v-b-tooltip.hover.left
                                                        :title="$root.text('Edit', null, true)"
                                                        @click.prevent="editProfileField(item)"
                                                    >
                                                        <i class="far fa-edit"></i>
                                                    </a>
                                                </span>
                                                <span v-else class="d-sm-none float-right">
                                                    <span>
                                                        <a
                                                            href="#"
                                                            v-b-tooltip.hover.left
                                                            :title="$root.text('Cancel', null, true)"
                                                            @click.prevent="editProfileField(item, false)"
                                                        >
                                                            <i class="far fa-times"></i>
                                                        </a>
                                                    </span>
                                                </span>
                                            </div>
                                            <div class="col-sm-10">
                                                <div v-if="(item.id > 0)">
                                                    <profile-field-edit-form
                                                        v-if="item.edit_mode"
                                                        :data="item"
                                                        :field_types="field_types"
                                                        :update="update_item"
                                                        :cancel="cancel_item"
                                                    >
                                                    </profile-field-edit-form>

                                                    <div v-else class="pt-1">
                                                        <!--<i
                                                            :class="'fal fa-'
                                                                + ((item.type == 'I') ? 'image' : 'input-text')
                                                                + ' fa-fw mr-1 font-size-16'"
                                                            style="position: relative; top: 1px"
                                                        ></i>-->
                                                        {{ item.title }}{{ item.title_suffix }}
                                                        <span v-if="item.required">*</span>
                                                        <span class="small text-muted ml-2">
                                                            ({{ getFieldTypeName(item) }})
                                                        </span>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <loading align="left" size="2"></loading>
                                                </div>
                                            </div>
                                            <div
                                                v-if="(item.id > 0)"
                                                class="col-sm-1 d-none d-sm-block font-size-18 contact-links"
                                            >
                                                <div v-if="!item.edit_mode">
                                                    <div class="float-right text-center" style="width: 21px">
                                                        <a
                                                            v-if="$root.hasPermission('profiles_fieldset_edit')"
                                                            href="#"
                                                            v-b-tooltip.hover.left
                                                            :title="$root.text('Edit', null, true)"
                                                            @click.prevent="editProfileField(item)"
                                                        >
                                                            <i class="far fa-edit"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <div>
                                                        <div class="float-right text-center" style="width: 21px">
                                                            <a
                                                                href="#"
                                                                v-b-tooltip.hover.left
                                                                :title="$root.text('Cancel', null, true)"
                                                                @click.prevent="editProfileField(item, false)"
                                                            >
                                                                <i class="far fa-times"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </draggable>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else class="alert alert-danger" role="alert">
            {{ $root.text('Sorry, you do not have access to this function.', null, true) }}
        </div>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';
    export default {
        components: { draggable },
        data() {
            return {
                loading: false,
                errors: false,
                field_types: [],
                profile_fields: [],
                update_item: 0,
                updating: false,
                cancel_item: 0,
                lang: 0,
                lang_interval: null
            };
        },
        mounted () {
            this.updateFieldTypes();
            $('body').removeClass('login-body');
            this.loading = true;
            this.getProfileFields();

            var vm = this;
            this.lang_interval = setInterval(function () {
                let currentLang = (vm.$root.current_language || 0);
                if (vm.lang != currentLang) {
                    vm.updateFieldTypes();
                    vm.lang = currentLang;
                }
            }, 500);
        },
        beforeDestroy() {
            if (this.lang_interval) {
                clearInterval(this.lang_interval);
            }
        },
        watch: {
            update_item: function (val) {
                this.updating = (val > 0);
            }
        },
        methods: {
            updateFieldTypes: function () {
                this.field_types = [
                    {
                        id: 'text',
                        title: this.$root.text('Single line text field', null, true)
                    },
                    {
                        id: 'textarea',
                        title: this.$root.text('Multi-line text field', null, true)
                    },
                    {
                        id: 'image',
                        title: this.$root.text('Image field', null, true)
                    },
                    {
                        id: 'qr',
                        title: this.$root.text('QR field', null, true)
                    }
                ];
            },
            getProfileFields: function (editField = null) {
                this.errors = false;

                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/profile-fields';
                var vm = this;
                var autoEditField = editField;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.profile_fields = response.data.profile_fields;
                    if (autoEditField) {
                        vm.profile_fields.forEach((item) => {
                            if (item.id == autoEditField) {
                                item.edit_mode = true;
                            }
                        });
                    }
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error
                        || vm.$root.text('Something went wrong, please try again', null, true);
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            onAddField: function (event) {
                this.errors = false;
                let type = 'I';
                let single_line = true;
                let suffix = '_L';
                switch (this.profile_fields[event.newIndex].id) {
                    case 'text':
                        type = 'T';
                        break;
                    case 'textarea':
                        type = 'T';
                        single_line = false;
                        break;
                    case 'qr':
                        type = 'T';
                        suffix = '_LQR';
                        break;
                }

                this.profile_fields[event.newIndex] = {
                    id: 0,
                    title: this.profile_fields[event.newIndex].title,
                    title_suffix: suffix,
                    type: type,
                    sort_order: event.newIndex,
                    single_line: single_line
                };
                
                /**
                 * Send create profile field request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/profile-field';
                const data = this.profile_fields[event.newIndex];
                var vm = this;
                var itemIndex = event.newIndex;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    // Update item id.
                    vm.profile_fields[itemIndex].id = response.data.profile_field_id;
                    vm.profile_fields[itemIndex].edit_mode = true;
                    vm.getProfileFields(response.data.profile_field_id);
                    vm.$root.profile_fields_changed = true;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error
                        || vm.$root.text('Something went wrong, please try again', null, true);
                    // remove item
                    vm.profile_fields.splice(itemIndex, 1);
                })
                .finally(() => {
                    // update draggable
                    vm.profile_fields.push({ id: -1 });
                    vm.profile_fields.pop();
                });
            },
            onMoveField: function(event) {
                if (event.newIndex == event.oldIndex) {
                    return false;
                }
                this.errors = false;

                /**
                 * Send move profile field request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/profile-field/'
                    + this.profile_fields[event.newIndex].id + '/move';
                const data = {
                    sort_order: event.newIndex
                };
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    // Do anything?
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                });
            },
            profileFieldsCount: function() {
                if (!this.profile_fields) {
                    return 0;
                }
                if (this.profile_fields.length == 0) {
                    return 0;
                }

                return this.profile_fields.length;
            },
            containerClass: function () {
                if (this.profileFieldsCount() == 0) {
                    return 'drop-container';
                }

                return '';
            },
            profileFieldsClass: function () {
                if (this.profileFieldsCount() == 0) {
                    return 'profile-fields list-group mt-3 mb-3 min-height';
                }

                return 'profile-fields list-group';
            },
            editProfileField: function (item, editMode = true) {
                if (this.updating) {
                    return null;
                }
                if (editMode) {
                    item.edit_mode = true;
                    this.profile_fields.push({ id: -1 });
                    this.profile_fields.pop();

                    return null;
                }
                this.cancel_item = item.id;
            },
            updateProfileField: function (item) {
                if (this.updating) {
                    return null;
                }
                this.update_item = item.id;
            },
            deleteProfileField: function (item, confirmed = false) {
                if (confirmed) {
                    this.errors = false;
                    this.loading = true;

                    /**
                     * Send delete profile field request to API
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/profile-field/'
                        + item;
                    var vm = this;

                    axios({
                        method: 'delete',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        vm.getProfileFields();
                        vm.$root.profile_fields_changed = true;
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // missing/lost API token - logout user
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            vm.errors = error.response.data.error;
                        }
                    });

                    return null;
                }

                if (this.updating) {
                    return null;
                }

                this.$parent.confirm_delete = {
                    title: this.$root.text('Delete ' + this.$root.contents.profile_title + ' field', null, true),
                    text: this.$root.text(
                        'Are you sure you want to delete: {title}?',
                        { title: item.title + (item.title_suffix ? item.title_suffix : '') },
                        true
                    ),
                    component: this,
                    action: 'delete-' + item.id
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action) {
                const itemId = parseInt(action.substr(7));
                this.deleteProfileField(itemId, true);
            },
            getFieldTypeName: function (profileField) {
                let fieldTypeName = null;
                this.field_types.forEach((item) => {
                    if (
                        ((item.id == 'image') && (profileField.type == 'I'))
                        || (
                            (item.id == 'text')
                            && (profileField.type == 'T')
                            && profileField.single_line
                            && ((profileField.title_suffix == '_L') || !this.$root.client.artwork_generate_qr_codes)
                        )
                        || (
                            (item.id == 'qr')
                            && (profileField.type == 'T')
                            && profileField.single_line
                            && (profileField.title_suffix == '_LQR')
                            && this.$root.client.artwork_generate_qr_codes
                        )
                        || ((item.id == 'textarea') && (profileField.type == 'T') && !profileField.single_line)
                    ) {
                        fieldTypeName = item.title;
                    }
                });

                return fieldTypeName;//.toLowerCase();
            },
            doNothing: function () {
                return null;
            },
            hasAccess: function () {
                if (this.$root.hasPermission('profiles_fieldset_add')) {
                    return true;
                }
                if (this.$root.hasPermission('profiles_fieldset_edit')) {
                    return true;
                }
                if (this.$root.hasPermission('profiles_fieldset_delete')) {
                    return true;
                }

                return false;
            }
        }
    }
</script>
<style scoped>
    .field-types div {
        cursor: move;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .field-types div:hover {
        background-color: #eff2f7;
    }

    .drag-item {
        border: 1px solid #999;
    }

    .drop-container {
        position: relative;
        border-left: 1px solid #eff2f7;
        border-right: 1px solid #eff2f7;
        border-bottom: 1px solid #eff2f7;
        border-bottom-left-radius: .25;
        border-bottom-right-radius: .25;
    }

    .min-height {
        min-height: 44px;
    }

    .drag-and-drop {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 190px;
        height: 20px;
    }

    .profile-fields .list-group-item {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .profile-fields .list-group-item:hover {
        background-color: #eff2f7;
    }
</style>
