<template>
    <div>
        <div v-if="hasCustomFieldFilters()" class="px-2">
            <span v-for="filter in asset_result_custom_fields" :key="filter.id">
                <span v-if="filter.selected" class="contact-links mr-3 mb-2">
                    <span
                        class="badge badge-primary font-size-11"
                        v-html="filter.text"
                        style="cursor: pointer"
                        @click="filterAction(filter)"
                    ></span>
                    <a
                        href="#"
                        @click.prevent="filterAction(filter, true)"
                        style="position: relative; top: 1px"
                    >
                        <i class="fal fa-times-circle"></i>
                    </a>
                </span>
            </span>
        </div>
        <div v-if="hasAssetTypeFilters()" class="px-2">
            <hr v-if="hasCustomFieldFilters()" class="my-2">
            <span v-for="filter in asset_result_types" :key="filter.id">
                <span v-if="filter.selected" class="contact-links mr-3 mb-2">
                    <span
                        class="badge badge-primary font-size-11"
                        v-html="filter.text"
                        style="cursor: pointer"
                        @click="filterAction(filter)"
                    ></span>
                    <a
                        href="#"
                        @click.prevent="filterAction(filter, true)"
                        style="position: relative; top: 1px"
                    >
                        <i class="fal fa-times-circle"></i>
                    </a>
                </span>
            </span>
        </div>
        <div style="position: relative">
            <div class="related-assets">
                <div v-if="filtering" class="mt-5">
                    <loading></loading>
                </div>
                <div v-else>
                    <div v-for="item in items" :key="item.id" class="preview ml-4">
                        <related-asset-preview :data="item"></related-asset-preview>
                    </div>
                </div>
            </div>
            <div class="ra-scroll-left contact-links text-center pt-1" @click="scrollAssets('left')">
                <a href="#" @click.prevent="doNothing()"><i class="fal fa-chevron-left"></i></a>
            </div>
            <div class="ra-scroll-right contact-links text-center pt-1" @click="scrollAssets('right')">
                <a href="#" @click.prevent="doNothing()"><i class="fal fa-chevron-right"></i></a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['asset'],
        data () {
            return {
                items: [],
                scroll_assets_check_interval: null,
                asset_result_custom_fields: null,
                custom_fields: [],
                asset_result_types: null,
                asset_types: [],
                filtering: false
            }
        },
        mounted () {
            this.getCustomFields();
            if (this.$root.client.asset_type_filter_menu) {
                this.getAssetTypes();
            }
            if (this.asset) {
                this.items = [];
                this.$parent.related_assets = false;
                this.getRelatedAssets(this.asset);
                this.asset_result_custom_fields = null;
                this.asset_result_types = null;
            }
            var vmRelatedAssets = this;
            vmRelatedAssets.scroll_assets_check_interval = setInterval(function () {
                vmRelatedAssets.scrollAssetsCheck();
            }, 999);
        },
        beforeDestroy() {
            clearInterval(this.scroll_assets_check_interval);
        },
        watch: {
            asset: function (val) {
                this.items = [];
                this.$parent.related_assets = false;
                this.getRelatedAssets(val);
                this.asset_result_custom_fields = null;
            }
        },
        methods: {
            getRelatedAssets: function (asset, counts = null) {
                let filters = ['sb::random', 'rr::' + asset.id, (asset.archived ? '' : '!') + 'archived'];
                if (counts) {
                    filters.push(counts);
                }
                if (this.asset_result_custom_fields) {
                    let allSelected = true;
                    this.asset_result_custom_fields.forEach((item) => {
                        if (!item.selected) {
                            allSelected = false;
                        }
                    });
                    if (!allSelected) {
                        this.asset_result_custom_fields.forEach((item) => {
                            if (item.selected) {
                                filters.push(item.key + '::' + btoa(item.value));
                            }
                        });
                    }
                }
                if (this.asset_result_types) {
                    let allSelected = true;
                    this.asset_result_types.forEach((item) => {
                        if (!item.selected) {
                            allSelected = false;
                        }
                    });
                    if (!allSelected) {
                        this.asset_result_types.forEach((item) => {
                            if (item.selected) {
                                filters.push('rt::' + item.value);
                            }
                        });
                    }
                }
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resources/' + filters.join('|');
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if ('custom_fields' in response.data) {
                        vm.setCustomFieldFilters(response.data.custom_fields);

                        return null;
                    }
                    if ('resource_types' in response.data) {
                        vm.setAssetTypeFilters(response.data.resource_types);
                        vm.getRelatedAssets(vm.asset, 'counts-cf');

                        return null;
                    }
                    vm.items = [];
                    response.data.resources.data.forEach((item) => {
                        // Duplicate display protection - check if already added.
                        let itemIndex = -1;
                        vm.items.forEach((existingItem, existingItemIndex) => {
                            if (item.id == existingItem.id) {
                                itemIndex = existingItemIndex;
                            }
                        });
                        if (itemIndex < 0) {
                            vm.items.push(item);
                        }
                    });
                    vm.$parent.related_assets = vm.items.length;
                    if (this.$root.client.asset_type_filter_menu) {
                        // Get asset type counts.
                        vm.getRelatedAssets(vm.asset, 'counts-rt');

                        return null;
                    }
                    // Get custom field counts.
                    vm.getRelatedAssets(vm.asset, 'counts-cf');

                    // Get rest of assets?
                    /*if (vm.is_expanded) {
                        vm.getAssets();
                    }*/
                })
                .catch(function (error) {
                    //
                })
                .finally(() => {
                    vm.filtering = false;
                });
            },
            isSelectedAsset: function (id) {
                return (this.$root.selected_assets.indexOf(id) > -1);
            },
            scrollAssetsCheck: function () {
                if ($('.related-assets')[0]) {
                    if ($('.related-assets')[0].scrollWidth > ($('.related-assets').outerWidth() + 24)) {
                        $('.ra-scroll-left').fadeIn('slow');
                        $('.ra-scroll-right').fadeIn('slow');

                        return null;
                    }
                }
                $('.ra-scroll-left').fadeOut('slow');
                $('.ra-scroll-right').fadeOut('slow');
            },
            scrollAssets: function (direction) {
                let steps = Math.ceil($('.related-assets').innerWidth() - 200);
                if (steps < 295) {
                    steps = 295;
                }
                if (direction == 'right') {
                    $('.related-assets').animate({ scrollLeft: '+=' + steps }, 700);

                    return null;
                }
                $('.related-assets').animate({ scrollLeft: '-=' + steps }, 700);
            },
            doNothing: function () {
                return null;
            },
            setCustomFieldFilters: function (customFields) {
                if (this.custom_fields.length == 0) {
                    this.asset_result_custom_fields = null;

                    return null;
                }
                if (this.asset_result_custom_fields === null) {
                    this.asset_result_custom_fields = [];
                }
                let id = 0;
                this.custom_fields.forEach((item) => {
                    if (
                        item.filter_menu
                        && (item.id in customFields)
                        && ('options' in item)
                        && (!item.related_assets || !this.assetHasCustomFieldValue(item.id))
                    ) {
                        if (item.options !== null) {
                            item.options.forEach((option) => {
                                if (option.value in customFields[item.id]) {
                                    let cfIndex = -1;
                                    this.asset_result_custom_fields.forEach((cf, index) => {
                                        if ((cf.key == 'cf' + item.id) && (cf.value == option.value)) {
                                            cfIndex = index;
                                        }
                                        if (cf.id >= id) {
                                            id = cf.id + 1;
                                        }
                                    });
                                    let selected = (customFields[item.id][option.value].filtered > 0);
                                    if (cfIndex > -1) {
                                        this.asset_result_custom_fields[cfIndex].selected = selected;
                                    } else {
                                        this.asset_result_custom_fields.push({
                                            id: id,
                                            key: 'cf' + item.id,
                                            value: option.value,
                                            text: option.text.replace(/"/g, '&quot;').replace(/'/g, '&apos;'),
                                            selected: selected
                                        });
                                        id++;
                                    }
                                }
                            });
                        }
                    }
                });
            },
            getCustomFields: function () {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/custom-fields';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.custom_fields = [];
                    response.data.custom_fields.forEach((item) => {
                        if ((item.type == 'singleselect') || (item.type == 'multiselect')) {
                            vm.custom_fields.push(item);
                        }
                    });
                })
                .catch(function (error) {
                    //
                });
            },
            hasCustomFieldFilters: function () {
                if (!this.asset_result_custom_fields) {
                    return false;
                }

                return (this.asset_result_custom_fields.length > 0);
            },
            setAssetTypeFilters: function (assetTypes) {
                if (this.asset_types.length == 0) {
                    this.asset_result_types = null;

                    return null;
                }
                if (this.asset_result_types === null) {
                    this.asset_result_types = [];
                }
                let id = 0;
                this.asset_types.forEach((item) => {
                    if (item.id in assetTypes) {
                        let atIndex = -1;
                        this.asset_result_types.forEach((at, index) => {
                            if (at.value == item.id) {
                                atIndex = index;
                            }
                            if (at.id >= id) {
                                id = at.id + 1;
                            }
                        });
                        let selected = (assetTypes[item.id].filtered > 0);
                        if (atIndex > -1) {
                            this.asset_result_types[atIndex].selected = selected;
                        } else {
                            this.asset_result_types.push({
                                id: id,
                                key: 'asset_type',
                                value: item.id,
                                text: item.name.replace(/"/g, '&quot;').replace(/'/g, '&apos;'),
                                selected: selected
                            });
                            id++;
                        }
                    }
                });
            },
            getAssetTypes: function () {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resource-types/!pagination';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.asset_types = response.data.resource_types;
                })
                .catch(function (error) {
                    //
                });
            },
            hasAssetTypeFilters: function () {
                if (!this.asset_result_types) {
                    return false;
                }

                return (this.asset_result_types.length > 0);
            },
            filterAction: function (filter, remove = false) {
                let filters = (filter.key == 'asset_type') ? this.asset_result_types : this.asset_result_custom_fields;
                let noneSelected = true;
                filters.forEach((item) => {
                    if (remove) {
                        if (item.id == filter.id) {
                            item.selected = false;
                        } else if (item.selected) {
                            noneSelected = false;
                        }
                    } else {
                        if (item.id == filter.id) {
                            item.selected = true;
                        } else {
                            item.selected = false;
                        }
                        noneSelected = false;
                    }
                });
                if (noneSelected) {
                    filters.forEach((item) => {
                        item.selected = true;
                    });
                }
                this.filtering = true;
                this.getRelatedAssets(this.asset);
            },
            assetHasCustomFieldValue: function (customFieldId) {
                if (!this.asset.custom_fields) {
                    return false;
                }
                let value = null;
                this.asset.custom_fields.forEach((item) => {
                    if (item.id == customFieldId) {
                        value = item.value;
                    }
                });

                return value ? true : false;
            }
        }
    }
</script>
<style scoped>
    .related-assets {
        position: relative;
        top: 10px;
        left: 10px;
        height: 270px;
        width: calc(100% - 18px);
        overflow-x: hidden;
        overflow-y: hidden;
        white-space: nowrap;
        display: inline-block;
    }

    .related-assets .preview {
        position: relative;
        display: inline-block;
        width: 250px;
        height: 204px;
        white-space: normal;
    }

    .related-assets .preview:first-child {
        margin-left: 0 !important;
    }

    .ra-scroll-left {
        position: absolute;
        top: 90px;
        left: -15px;
        cursor: pointer;
        display: none;
        font-size: 30px;
        width: 40px;
        height: 40px;
        background-color: rgba(255, 255, 255, 0.85);
        border-radius: 20px;
        line-height: 30px;
    }
    
    .ra-scroll-right {
        position: absolute;
        top: 90px;
        right: -15px;
        cursor: pointer;
        display: none;
        font-size: 30px;
        width: 40px;
        height: 40px;
        background-color: rgba(255, 255, 255, 0.85);
        border-radius: 20px;
        line-height: 30px;
    }
</style>
