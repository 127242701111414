<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>
        <vue-dropzone
            ref="proofLinkedFileDropzone"
            :id="'proof-linked-file-dropzone-' + form"
            :options="dropzone_options"
            v-on:vdropzone-sending="onSendingFile"
            v-on:vdropzone-upload-progress="onProgressUpdate"
            v-on:vdropzone-success="onFileUploaded"
            v-on:vdropzone-error="onUploadError"
            v-on:vdropzone-drag-over="onDragOver"
            v-on:vdropzone-drag-leave="onDragLeave"
            v-on:vdropzone-drop="onDragLeave"
            v-on:vdropzone-file-added="onFileAdded"
            :useCustomSlot=true
        >
            <div v-if="file" class="dz-uploaded-file p-2 mb-1">
                <div class="dz-thumbnail">
                    <span v-if="!file.preview_image">
                        <img
                            :src="$root.imagePlaceholder(file.filename)"
                            class="img-fluid"
                            alt=""
                        >
                    </span>
                    <img
                        v-else
                        :src="file.preview_image"
                        class="img-fluid"
                        alt=""
                    >
                </div>
                <div class="dz-filename">{{ file.filename }}</div>
                <div class="dz-size" v-html="$root.formatFileSize(file.file_size)"></div>
                <div class="dz-remove">
                    <a
                        href="#"
                        v-b-tooltip.hover
                        title="Delete"
                        @click.prevent="removeUploadedFile()"
                    >
                        <i class="fal fa-trash-alt"></i>
                    </a>
                </div>
                <div
                    class="dz-upload-info"
                    :id="'upload-info' + file.id"
                    style="left: 160px"
                >
                    <!--<i class="fal fa-clock mr-1"></i> Pending upload-->
                </div>
                <div
                    class="dz-progress"
                    :id="'upload-progress' + file.id"
                    style="width: calc(100% - 172px)"
                >
                    <div class="dz-upload"></div>
                </div>
            </div>
            <a
                href="#"
                class="dz-add-files"
                :id="'proof-linked-file-add-files-' + form"
                @click.prevent="doNothing()"
                :style="dzAddFilesStyle()"
            >
                <i class="far fa-cloud-upload-alt font-size-20 mr-1"></i>
                Click or drop file here to upload
            </a>
        </vue-dropzone>
    </div>
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone';
    export default {
        components: {
            'vue-dropzone': vue2Dropzone
        },
        props: ['form'],
        data () {
            return {
                errors: false,
                dropzone_options: {
                    url: this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-v2',
                    createImageThumbnails: true,
                    maxThumbnailFilesize: 20,
                    thumbnailWidth: 50,
                    thumbnailHeight: 50,
                    uploadMultiple: false,
                    parallelUploads: 1,
                    chunking: true,
                    forceChunking: true,
                    parallelChunkUploads: true,
                    chunkSize: 6000000,
                    retryChunks: true,
                    retryChunksLimit: 3,
                    maxFilesize: (10 * 1000 * 1000 * 1000),
                    autoProcessQueue: false,
                    timeout: 0,
                    clickable: '#proof-linked-file-add-files-' + this.form,
                    headers: {
                        'Authorization': 'Bearer ' + this.$root.token
                    }
                },
                file: null,
                uploading: false
            }
        },
        mounted () {
            //
        },
        watch: {
            //
        },
        methods: {
            reset: function () {
                this.errors = false;
                this.file = null;
                this.uploading = null;
                if (this.$refs.proofLinkedFileDropzone) {
                    this.$refs.proofLinkedFileDropzone.removeAllFiles();
                }
            },
            doNothing: function () {
                return false;
            },
            dzAddFilesStyle: function () {
                if (!this.file) {
                    return 'display: block';
                }

                return 'display: none';
            },
            onFileAdded: function (file) {
                if (!this.uploading) {
                    this.errors = false;
                }
                if (this.file) {
                    this.$refs.proofLinkedFileDropzone.removeFile(file);

                    return null;
                }

                // Check file size.
                if (file.size > this.dropzone_options.maxFilesize) {
                    this.$refs.proofLinkedFileDropzone.removeFile(file);
                    const appComponent = this.$root.findComponent(this.$root, 'main-app');
                    appComponent.message = {
                        type: 'error',
                        title: 'Maximum upload size exceeded',
                        text: 'The maximum size of the image you can upload is '
                            + this.$root.formatFileSize(this.dropzone_options.maxFilesize) + '. '
                            + 'The size of ' + file.name + ' is '
                            + this.$root.formatFileSize(file.size) + '.'
                    };
                    $('#message-modal').modal('show');
                    $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });

                    return null;
                }

                this.errors = false;
                let newFile = {
                    id: file.upload.uuid,
                    upload_id: this.$root.getUploadFileId(file),
                    filename: file.name,
                    file_type: file.type,
                    file_size: file.size,
                    upload: {
                        progress: 0,
                        bytes_sent: 0
                    },
                    preview_image: (file.dataURL || null)
                };
                this.file = newFile;
                var vm = this;
                setTimeout(function () {
                    if (vm.file) {
                        vm.$refs.proofLinkedFileDropzone.processQueue();
                        vm.uploading = true;
                        $('#upload-info' + vm.file.id).html('Preparing upload...');
                    }
                }, 500);
            },
            onDragOver: function (event) {
                $('#proof-linked-file-dropzone-' + this.form).addClass('dz-drag-highlight');
            },
            onDragLeave: function (event) {
                $('#proof-linked-file-dropzone-' + this.form).removeClass('dz-drag-highlight');
            },
            onSendingFile: function (file, xhr, formData) {
                formData.append('id', this.$root.getUploadFileId(file));
            },
            onProgressUpdate: function (file, progress, bytesSent) {
                if (!this.file) {
                    return null;
                }
                if ((progress < 100) && (this.file.id == file.upload.uuid)) {
                    if (isNaN(bytesSent)) {
                        bytesSent = file.size * (progress / 100);
                    }
                    this.file.upload.progress = progress;
                    this.file.upload.bytes_sent = bytesSent;
                    $('#upload-info' + this.file.id)
                        .html(this.$root.formatFileSize(bytesSent) + ' ' + progress.toFixed(1) + '%');
                    $('#upload-progress' + this.file.id + ' > div').css('width', progress + '%');
                }
            },
            onFileUploaded: function (file, response) {
                if (!this.file) {
                    return null;
                }
                if (this.file.id == file.upload.uuid) {
                    this.file.upload.progress = 100;
                    this.file.upload.bytes_sent = file.size;
                    $('#upload-info' + this.file.id).html(
                        this.file.preview_image ? 'Finalising upload...' : 'Previewing...'
                    );
                    $('#upload-progress' + this.file.id + ' > div').css('width', '100%');
                    if (!this.file.preview_image) {
                        this.file.preview_image = (file.dataURL || null);
                    }

                    this.getUploadInfo(this.file.upload_id);
                    this.$refs.proofLinkedFileDropzone.removeFile(file);
                }
            },
            getUploadInfo: function (fileId) {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-info/' + fileId
                    + '/preview/500x500';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (vm.file.upload_id != response.data.file_id) {
                        return null;
                    }
                    vm.file.temp_file = response.data.temp_file;
                    vm.onUploadComplete();
                    $('#upload-info' + vm.file.id).html('');
                    
                    if (response.data.preview) {
                        vm.file.preview_image = response.data.preview.src;
                    }
                    vm.$emit('file-uploaded', vm.file);
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            onUploadComplete: function () {
                this.uploading = false;
            },
            onUploadError: function (file, message, xhr) {
                if (file.status != 'canceled') {
                    this.errors = 'Something went wrong, please try again';
                }
                this.uploading = false;
                console.log(file, message);
            },
            removeUploadedFile: function(confirmed = false) {
                if (confirmed) {
                    const removeUploadFileId = (this.file.upload.progress < 100) ? this.file.id : null;

                    this.file = null;
                    this.errors = false;

                    if (removeUploadFileId) {
                        this.$refs.proofLinkedFileDropzone.getUploadingFiles().forEach((file) => {
                            if (file.upload.uuid == removeUploadFileId) {
                                this.$refs.proofLinkedFileDropzone.removeFile(file);
                            }
                        });
                        this.$refs.proofLinkedFileDropzone.getQueuedFiles().forEach((file) => {
                            if (file.upload.uuid == removeUploadFileId) {
                                this.$refs.proofLinkedFileDropzone.removeFile(file);
                            }
                        });
                    }
                
                    return null;
                }

                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.confirm_delete = {
                    title: 'Delete file',
                    text: 'Are you sure you want to delete: ' + this.file.filename + '?',
                    component: this,
                    action: 'delete-file'
                };
                $('#confirm-delete-modal').modal('show');
                $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });
            },
            confirmDelete: function (action) {
                if (action == 'delete-file') {
                    this.removeUploadedFile(true);
                }
            }
        }
    }
</script>
<style scoped>
    .vue-dropzone {
        min-height: 126px;
    }

    .dz-add-files {
        padding-top: 45px;
    }

    .dz-uploaded-file {
        cursor: default;
    }
</style>
