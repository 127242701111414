<template>
    <div>
        <form v-on:submit.prevent="onSubmit()">
            <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

            <div class="row">
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="form-group">
                        <label
                            :for="'subfolder-name' + subfolder.id"
                            :id="'subfolder-name-label' + subfolder.id"
                            class="mandatory-label"
                        >
                            Name *
                        </label>
                        <input
                            v-model="subfolder.name"
                            type="text"
                            :id="'subfolder-name' + subfolder.id"
                            class="form-control mandatory-field"
                            :readonly="updating"
                            v-on:keyup="$root.unHighlightErrors('subfolder-name', subfolder.id)"
                            v-on:change="$root.unHighlightErrors('subfolder-name', subfolder.id)"
                        >
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="form-group pt-md-4">
                        <div class="custom-control custom-checkbox pt-md-2">
                            <input
                                v-model="subfolder.related_assets"
                                type="checkbox"
                                class="custom-control-input"
                                :id="'subfolder-related_assets' + subfolder.id"
                                :disabled="updating"
                            >
                            <label class="custom-control-label" :for="'subfolder-related_assets' + subfolder.id">
                                Related {{ $root.contents.asset_title }}s field
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col"></div>
            </div>

            <span v-if="!updating">
                <button
                    :key="'save'"
                    type="submit"
                    :class="'btn btn-primary mb-2' + ($root.is_md_screen ? ' mr-2' : ' btn-block')"
                >
                    <i class="far fa-save mr-1"></i> Save
                </button>
            </span>
            <span v-else>
                <button
                    :key="'save-spinner'"
                    type="button"
                    :class="'btn btn-primary mb-2' + ($root.is_md_screen ? ' mr-2' : ' btn-block')"
                >
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">Save</span>
                </button>
            </span>
            <button
                v-if="subfolder.archived"
                type="button"
                :class="'btn btn-secondary mb-2' + ($root.is_md_screen ? ' mr-2' : ' btn-block')"
                data-toggle="modal"
                data-target="#subfolder-de-archive-modal"
                @click="resetDeArchiveSubfolder()"
                :disabled="updating"
            >
                <i class="far fa-archive mr-1"></i> De-archive
            </button>
            <button
                v-else
                type="button"
                :class="'btn btn-secondary mb-2' + ($root.is_md_screen ? ' mr-2' : ' btn-block')"
                data-toggle="modal"
                data-target="#subfolder-archive-modal"
                @click="resetArchiveSubfolder()"
                :disabled="updating"
            >
                <i class="far fa-archive mr-1"></i> Archive
            </button>
            <button
                type="button"
                :class="'btn btn-secondary mb-2' + ($root.is_md_screen ? '' : ' btn-block')"
                data-toggle="modal"
                data-target="#subfolder-delete-modal"
                @click="resetDeleteSubfolder()"
                :disabled="updating"
            >
                <i class="far fa-trash-alt mr-1"></i> Delete
            </button>
        </form>
    </div>
</template>

<script>
    export default {
        props: ['data', 'list_component'],
        data () {
            return {
                errors: false,
                updating: false,
                subfolder: {}
            }
        },
        mounted () {
            if (this.data) {
                this.subfolder = this.data;
            }
        },
        watch: {
            data: function (val) {
                this.subfolder = val;
            }
        },
        methods: {
            onSubmit: function () {
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
                this.errors = false;
                this.updating = true;
                /**
                 * Send update subfolder request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/folder/'
                    + this.subfolder.folder_id + '/subfolder/' + this.subfolder.id;
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: vm.subfolder,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    // show confirmation message
                    vm.list_component.$parent.message = {
                        type: 'success',
                        title: 'Update ' + vm.$root.contents.subfolder_title,
                        text: response.data.message
                    };
                    $('#message-modal').modal('show');
                    vm.$root.folders_changed = true;

                    // close after 2 seconds automatically
                    setTimeout(function() {
                        $('#message-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = 'Something went wrong, please try again';
                        if (typeof error.response.data.error === 'string') {
                            vm.errors = error.response.data.error;
                        } else if (typeof error.response.data.error === 'object') {
                            vm.errors = '';
                            for (const [key, value] of Object.entries(error.response.data.error)) {
                                vm.errors += value.join('<br>') + '<br>';
                                $('#subfolder-' + key + '-label' + vm.subfolder.id).addClass('text-danger');
                                $('#subfolder-' + key + vm.subfolder.id).addClass('field-error');
                            }
                        }
                    }
                })
                .finally(() => {
                    vm.updating = false;
                });
            },
            resetDeleteSubfolder: function () {
                const component = this.$root.findComponent(this.list_component, 'subfolder-delete');
                if (component) {
                    component.reset(this.subfolder, this.subfolder.archived ? this.$parent : this.$parent.$parent);
                }
            },
            resetArchiveSubfolder: function () {
                const component = this.$root.findComponent(this.list_component, 'subfolder-archive');
                if (component) {
                    component.reset(this.subfolder, this.$parent.$parent.$parent);
                }
            },
            resetDeArchiveSubfolder: function () {
                const component = this.$root.findComponent(this.list_component, 'subfolder-de-archive');
                if (component) {
                    component.reset(this.subfolder, this.$parent.$parent);
                }
            }
        }
    }
</script>
